<template>
	<div class="price-block">
		<div class="price-block__inpts">
			<or-input
				class="main-type"
				v-model.trim="price.min"
				type="number"
				:placeholder="`Min ${activeMoney} ${formatCash(+range.from || 0)}`"
			/>
			<or-input
				class="main-type"
				v-model.trim="price.max"
				type="number"
				:placeholder="`Max ${activeMoney} ${formatCash(+range.to || 0)}`"
			/>
		</div>
	</div>
</template>

<script>
import { simbol } from '@/enums/moneyСollections.js'
import { formatCash } from '@/utils/formatMoney'

export default {
	methods: { formatCash },
	props: {
		modelValue: {
			type: Object,
			default: () => ({
				from: null,
				to: null
			})
		},
		range: {
			type: Object,
			default: () => ({
				from: null,
				to: null
			})
		}
	},
	data() {
		return {
			price: {
				min: this.modelValue.from,
				max: this.modelValue.to
			}
		}
	},
	watch: {
		price: {
			handler() {
				const from = this.price.min?.replace(/^0+/, '').replace(/[^0-9]/g, '')
				const to = this.price.max?.replace(/^0+/, '').replace(/[^0-9]/g, '')
				this.$emit('update:modelValue', {
					from: from?.length > 0 ? from : undefined,
					to: to?.length > 0 ? to : undefined
				})
			},
			deep: true
		}
	},
	computed: {
		activeMoney() {
			return simbol[this.$store.state.activeMoney]
		}
	}
}
</script>

<style lang="scss">
.price-block {
	width: 380px;
	padding: 20px;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	@media (max-width: 768px) {
		width: 100%;
	}

	&__inpts {
		display: flex;
		gap: 10px;
		margin-bottom: 10px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	&__item {
		&.result-item:hover {
			background: transparent;
			cursor: default;
		}
	}
}
</style>
