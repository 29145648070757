import { i18n } from '../i18n.js'

export const rooms = [
	{ title: i18n.global.t('general.studio'), value: 0 },
	{ title: 1, value: 1 },
	{ title: 2, value: 2 },
	{ title: 3, value: 3 },
	{ title: 4, value: 4 },
	{ title: '5+', value: 5 }
]

export const baths = [
	{ title: 1, value: 1 },
	{ title: 2, value: 2 },
	{ title: 3, value: 3 },
	{ title: 4, value: 4 },
	{ title: '5+', value: 5 }
]
