<template>
	<div class="price-block">
		<div class="price-block__inpts">
			<or-input
				class="main-type"
				v-model.trim="count.min"
				type="number"
				step="1"
				:prefix="currentSquareSymbol"
				:placeholder="`Min ${formatNumber(+range.from)}`"
			/>
			<or-input
				class="main-type"
				v-model.trim="count.max"
				step="1"
				type="number"
				:prefix="currentSquareSymbol"
				:placeholder="`Max ${formatNumber(+range.to)}`"
			/>
		</div>
	</div>
</template>

<script>
import { simbol } from '@/enums/moneyСollections.js'
import { squareSymbols } from '@/enums/areaCollections.js'
import {formatNumber} from "@/utils/formatMoney";

export default {
	methods: {formatNumber},
	props: {
		modelValue: {
			type: Object,
			default: () => ({
				from: null,
				to: null
			})
		},
		range: {
			type: Object,
			default: () => ({
				from: null,
				to: null
			})
		}
	},
	data() {
		return {
			count: {
				min: this.modelValue.from,
				max: this.modelValue.to
			}
		}
	},
	watch: {
		count: {
			handler() {
				const from = this.count.min?.replace(/^0+/, '').replace(/[^0-9]/g,'');
				const to = this.count.max?.replace(/^0+/, '').replace(/[^0-9]/g,'');
				this.$emit('update:modelValue', {
					from: from?.length > 0 ? from : undefined,
					to: to?.length > 0 ? to : undefined
				})
			},
			deep: true
		}
	},
	computed: {
		currentSquareSymbol() {
			return squareSymbols[this.$store.state.areaUnit]
		},
		activeMoney() {
			return simbol[this.$store.state.activeMoney]
		}
	}
}
</script>
