<template>
	<div class="rooms-baths">
		<div class="rooms-baths__item">
			<p class="result-item">
				<span class="result-item__title">Rooms</span>
				<!-- <span class="result-item__text">108 offers from $1,5M</span> -->
			</p>
			<ul class="rooms-baths__list">
				<li
					v-for="room in rooms"
					:key="room.value"
					@click="updateValue(room.value.toString(), 'rooms')"
					:class="{
						active: count.rooms.includes(room.value.toString()),
						nonactive:
							availableBedrooms[room.value].count === 0 &&
							!count.rooms.includes(room.value.toString())
					}"
				>
					{{ room.title }}
				</li>
			</ul>
		</div>
		<div v-if="isShowBath" class="rooms-baths__item">
			<p class="result-item">
				<span class="result-item__title">Baths</span>
				<!-- <span class="result-item__text">56 offers from $1,2M</span> -->
			</p>
			<ul class="rooms-baths__list">
				<li
					v-for="bath in baths"
					:key="bath.value"
					@click="updateValue(bath.value.toString(), 'baths')"
					:class="{
						active: count.baths.includes(bath.value.toString()),
						nonactive:
							availableBathrooms[bath.value - 1].count === 0 &&
							!count.baths.includes(bath.value.toString())
					}"
				>
					{{ bath.title }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { rooms, baths } from '@/enums/roomsBaths.js'

export default {
	props: {
		bathrooms: {
			type: Array,
			default: () => []
		},
		bedrooms: {
			type: Array,
			default: () => []
		},
		availableBathrooms: {
			type: Array,
			default: () => []
		},
		availableBedrooms: {
			type: Array,
			default: () => []
		},
		isShowBath: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			count: {
				baths: this.bathrooms,
				rooms: this.bedrooms
			}
		}
	},
	// watch: {
	// 	count: {
	// 		handler(payload) {
	// 			this.$emit('update', payload)
	// 		},
	// 		deep: true
	// 	}
	// },
	computed: {
		rooms() {
			return rooms
		},
		baths() {
			return baths
		}
	},
	methods: {
		updateValue(value, field) {
			const idx = this.count[field].findIndex((itm) => itm === value)
			if (idx < 0) {
				this.count[field].push(value)
			} else {
				this.count[field].splice(idx, 1)
			}

			if (field === 'baths') {
				this.$emit('update:bathrooms', this.count[field])
			} else if (field === 'rooms') {
				this.$emit('update:bedrooms', this.count[field])
			}
		}
	}
}
</script>

<style lang="scss">
.rooms-baths {
	width: 340px;
	padding: 20px;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;

	@media (max-width: 410px) {
		width: 100%;
	}

	&__item {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;

		p {
			margin: 0;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		padding-left: 10px;
		flex-basis: 100%;

		li {
			padding: 10px;
			border: 1px solid #c99d56;
			border-radius: 4px;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #000000;
			cursor: pointer;

			&.active {
				color: #ffffff;
				background-color: #c99d56;
			}

			&.nonactive {
				border-color: rgba(0, 0, 0, 0.1);
				color: #808080;
			}
		}
	}
}
</style>
