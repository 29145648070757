<template>
	<div class="search-list">
		<div class="search-list__search-container">
			<or-input
				placeholder="Search"
				class="main-type"
				icon-before="search"
				v-model="searchString"
			/>
		</div>
		<!--		<div class="search-list__content">-->
		<!--			<ul class="search-list__list">-->
		<!--				<li-->
		<!--					v-for="(item, index) in selected"-->
		<!--					:key="index"-->
		<!--					class="search-list__item result-item"-->
		<!--					:class="{ active: selected.find((itm) => itm.slug === item.slug) }"-->
		<!--					@click.stop.prevent="select(item)"-->
		<!--				>-->
		<!--					<span class="result-item__title"-->
		<!--						>{{ item.title }} {{ item.announcement_count }}</span-->
		<!--					>-->
		<!--					<span class="result-item__text">{{ getInfo(item) }}</span>-->
		<!--				</li>-->
		<!--			</ul>-->
		<!--		</div>-->
		<!--		<hr v-if="items.length && selected.length" />-->
		<div class="search-list__content">
			<ul class="search-list__list">
				<li
					v-for="(item, index) in items"
					:key="index"
					class="search-list__item result-item"
					:class="{
						active: item.active,
						nonactive: item.announcement_count === 0
					}"
					@click.stop.prevent="select(item)"
				>
					<span class="result-item__title">{{ item.title }}</span>
					<span class="result-item__text">{{ getInfo(item) }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		projects: {
			type: Array,
			default: () => []
		},
		modelValue: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			results: this.projects,
			loading: false,
			searchString: ''
		}
	},
	computed: {
		items() {
			return this.projects
				.map((project) => {
					const isActive = this.modelValue.includes(project.slug.toString())
					return {
						...project,
						active: isActive
					}
				})
				.filter((project) =>
					project.title?.toLowerCase().includes(this.searchString.toLowerCase())
				)
		}
		// items() {
		// 	return this.projects.filter(
		// 		(project) =>
		// 			!this.modelValue.includes(project.slug.toString()) &&
		// 			project.title?.toLowerCase().includes(this.searchString.toLowerCase())
		// 	)
		// }
	},
	methods: {
		getInfo(payload) {
			return `${payload.announcement_count || payload.units_count} ${this.$t(
				'home.offers'
			)}`
		},
		select(payload) {
			const newValue = [...this.modelValue]
			const idx = newValue.findIndex((itm) => itm === payload.slug.toString())
			if (idx === -1) {
				newValue.push(payload.slug)
			} else {
				newValue.splice(idx, 1)
			}
			this.$emit('update:modelValue', newValue)
		}
	}
}
</script>

<style lang="scss"></style>
