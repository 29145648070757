<template>
	<div class="search-list">
		<div class="search-list__search-container">
			<or-input
				placeholder="Search"
				class="main-type"
				icon-before="search"
				v-model="searchString"
			/>
		</div>

		<!--		<div class="search-list__content">-->
		<!--			<ul class="search-list__list">-->
		<!--				<li-->
		<!--					v-for="(item, index) in selected"-->
		<!--					:key="index"-->
		<!--					class="search-list__item result-item"-->
		<!--					:class="{ active: selected.find((itm) => itm.id === item.id) }"-->
		<!--					@click.stop.prevent="select(item)"-->
		<!--				>-->
		<!--					<span class="result-item__title">{{ item.title }}</span>-->
		<!--					<span class="result-item__text">{{ getInfo(item) }}</span>-->
		<!--				</li>-->
		<!--			</ul>-->
		<!--		</div>-->

		<!--		<hr v-if="items.length && selected.length" />-->

		<div class="search-list__content">
			<ul class="search-list__list">
				<li
					v-for="(item, index) in items"
					:key="index"
					class="search-list__item result-item"
					:class="{
						active: item.active,
						nonactive: item.announcement_count === 0
					}"
					@click.stop.prevent="select(item)"
				>
					<span class="result-item__title">{{ item.title }}</span>
					<span class="result-item__text">{{ getInfo(item) }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import debounce from 'lodash/debounce'
import { formatCash } from '@/utils/formatMoney'
import { simbol, money } from '@/enums/moneyСollections.js'

// TODO:
// Добавить скролинг пагинацию

export default {
	props: {
		regions: {
			type: Array,
			default: () => []
		},
		modelValue: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			// selected: [],
			results: this.regions,
			loading: false,
			searchString: ''
		}
	},
	computed: {
		items() {
			return this.regions
				.map((region) => {
					const isActive = this.modelValue.includes(region.id.toString())
					return {
						...region,
						active: isActive
					}
				})
				.filter((region) =>
					region.title?.toLowerCase().includes(this.searchString.toLowerCase())
				)
		}
		// items() {
		// 	return this.regions.filter(
		// 		(region) =>
		// 			!this.modelValue.includes(region.id.toString()) &&
		// 			region.title?.toLowerCase().includes(this.searchString.toLowerCase())
		// 	)
		// }
	},
	methods: {
		getInfo(payload) {
			if (payload.announcement_count) {
				const price = 'price_from' in payload ? payload.price_from : undefined
        let displayString = `${payload.announcement_count ?? payload.units_count} ${this.$t('home.offers')}`
        if (typeof price !== 'undefined') displayString += ` from ${simbol[this.$store.state.activeMoney]}${price ? formatCash(price) : 0}`
				return displayString
			} else {
				return `${payload.announcement_count ?? payload.units_count} ${this.$t('home.offers')}`
			}
		},
		select(payload) {
			const newValue = [...this.modelValue]
			const idx = newValue.findIndex((itm) => itm === payload.id.toString())

			if (idx === -1) {
				newValue.push(payload.id)
			} else {
				newValue.splice(idx, 1)
			}

			this.$emit('update:modelValue', newValue)
		}
	}
}
</script>

<style lang="scss"></style>
