<template>
	<div class="property-type">
		<ul class="property-type__list">
			<template v-for="item in values" :key="item.id">
				<li
					v-if="item.title"
					class="property-type__item"
					:class="{
						active: modelValue.includes(item.id.toString()),
						nonactive:
							item.announcement_count === 0 &&
							!modelValue.includes(item.id.toString())
					}"
				>
					<or-checkbox
						style-type="main"
						name="property-type"
						:checked="modelValue.includes(item.id.toString())"
						:label="item.title"
						:subLabel="getLabel(item)"
						:value="item.id"
						v-on:update:checked="(e) => check(item, e)"
					/>
				</li>
			</template>
		</ul>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus'

export default {
	props: {
		isHiddenOnClick: {
			type: Boolean,
			default: true
		},
		values: {
			type: Array,
			default: () => []
		},
		modelValue: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			checked: this.modelValue
		}
	},
	// mounted() {
	// 	if (this.defaultValues) {
	// 		this.checked = this.defaultValues.split(',')
	// 	}
	// },
	methods: {
		capitalize(str) {
			return str && str[0].toUpperCase() + str.slice(1)
		},
		select(payload) {
			this.$emit('select', payload)

			// Закрываем дроп даун
			if (this.isHiddenOnClick) {
				eventBus.trigger('dropdown-hide')
			}
		},
		check(item, value) {
			const newValue = [...this.modelValue]
			const idx = newValue.findIndex(
				(propertyId) => propertyId === item.id.toString()
			)

			if (idx === -1) {
				newValue.push(item.id.toString())
			} else {
				newValue.splice(idx, 1)
			}

			this.$emit('update:modelValue', newValue)
		},
		getLabel(payload) {
			return `${payload.announcement_count || payload.units_count} ${this.$t(
				'home.offers'
			)}`
		}
	}
}
</script>

<style lang="scss">
.property-type {
	min-width: 250px;
	padding: 30px;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;

	&__item {
		display: flex;
		justify-content: space-between;
		max-width: 100% !important;
		margin-bottom: 2px;
		label {
			padding: 7px 10px;
			align-items: center;
		}
		.main-checkbox {
			height: unset;
		}
		&.active {
			label {
				background: #c99d56;
				border-radius: 4px;
				.checkbox-label {
					color: white;
				}
				p {
					color: white;
				}
			}
		}

		&.nonactive {
			pointer-events: none;
			.checkbox-label,
			p {
				color: #cfcfcf;
			}
		}
	}
}
</style>
